<template>
    <div class="hot-keyword-content">
        <div class="search-content">
            <el-input v-model="searchHotKeywordsValue"
                      placeholder="请输入搜索内容"
                      slot="prepend"
                      class="w300-input"
                      @keyup.enter.native="hotKeywordsSearch">
                <el-button slot="append" icon="el-icon-search" @click="hotKeywordsSearch"></el-button>
            </el-input>
        </div>
        <el-table :data="hotKeywordsList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column prop="name" label="关键词"></el-table-column>
            <el-table-column prop="search_index" label="搜索指数" align="center" sortable></el-table-column>
            <el-table-column prop="search_click_index" label="搜索点击指数" align="center" sortable></el-table-column>
            <el-table-column prop="click_rate" label="点击率" align="center" sortable>
                <template slot-scope="scope">
                    <span>{{scope.row.click_rate}}%</span>
                </template>
            </el-table-column>
            <el-table-column prop="turnover_index" label="成交金额指数" align="center" sortable> </el-table-column>
            <el-table-column prop="turnover_conversion_rate" label="成交转化率 " align="center" sortable>
                <template slot-scope="scope">
                    <span>{{scope.row.turnover_conversion_rate}}%</span>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="hotKeywordsPages.currentPageNum"
                       :page-size="hotKeywordsPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="hotKeywordsPages.total"
                       v-if="hotKeywordsList.length >0"
                       @current-change="pagesHotKeywordsCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {examStuExamKeyword} from '@/utils/apis'
    export default {
        name: "HotKeyword",
        data(){
            return{
                examModuleId: Number(this.$route.query.exam_module_id)||'',
                hotKeywordsList:[
                    {
                        name:'',
                        search_index:'',
                        search_click_index:'',
                        click_rate:'',
                        turnover_index:'',
                        turnover_conversion_rate:'',
                    }

                ],//热门关键词
                searchHotKeywordsValue:'',
                hotKeywordsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getExamStuExamKeyword()
        },
        methods:{
            // 获取关键词
            getExamStuExamKeyword(){
                let param = {
                    page: this.hotKeywordsPages.currentPageNum,
                    pageSize:this.hotKeywordsPages.eachPageNum,
                    paging:1
                }
                if (this.searchHotKeywordsValue) {
                    param.keyword = this.searchHotKeywordsValue
                    param.paging = 1
                }
                if (this.$route.query.id) {
                    param.shop_management = this.$route.query.id
                }
                if (this.$route.query.exam_module_id) {
                    param.exam_module_id = this.$route.query.exam_module_id;
                }
                examStuExamKeyword(param).then((res) => {
                    if (res.data.data){
                        this.hotKeywordsList = res.data.data
                        this.hotKeywordsPages.total = res.data.total;
                    }else{
                        this.hotKeywordsList = []
                        this.hotKeywordsPages.total = 0;
                    }
                })
            },
            // 热门关键词搜索
            hotKeywordsSearch(){
                this.getExamStuExamKeyword()
            },
            pagesHotKeywordsCurrentChange(val) {
                this.hotKeywordsPages.currentPageNum = val
                
                this.getExamStuExamKeyword()
            },
        }
    }
</script>

<style scoped lang="scss">
    .hot-keyword-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding:  0 20px;
        .search-content{
            margin-top: 20px;
        }
    }

</style>